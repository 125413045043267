<template>
  <Loader v-if="isLoading" />
  <div
    v-else
    class="tw-grid tw-grid-cols-1 tw-gap-12 md:tw-grid-cols-4 md:tw-mb-12"
  >
    <div class="tw-col-span-1 md:tw-col-span-4">
      <h2 class="tw-text-5xl tw-font-bold">{{ cardHeaderText.title }}</h2>
      <span>{{ cardHeaderText.description }}</span>
    </div>
    <Card
      v-for="(item, index) in items"
      :key="index"
      :illustration="item.meta.illustration"
      :title="translate(item.name)"
      :route="item.path"
      :open-link-on-new-tab="item.meta.isExternal"
      @click.once="overrideClick($event, item)"
    >
      <p>
        {{
          item.description ? translate(item.description) : translate(item.name)
        }}
      </p>
    </Card>
    <div
      v-if="showBookingItems"
      class="tw-grid tw-grid-cols-subgrid tw-grid-cols-1 tw-gap-12 tw-col-start-1 md:tw-grid-cols-4 md:tw-col-span-4"
    >
      <div class="tw-col-span-1 md:tw-col-span-4">
        <h2 class="tw-text-5xl tw-font-bold">
          {{ getCardHeaderText('BOOKING').title }}
        </h2>
        <span>{{ getCardHeaderText('BOOKING').description }}</span>
      </div>
      <div class="card-grid">
        <Card
          v-for="(item, index) in bookingItems"
          :key="index"
          :illustration="item.meta.illustration"
          :title="item.name"
          :link="item.url"
          :open-link-on-new-tab="item.meta.isExternal"
          class="card"
        >
          <p>
            {{ item.description }}
          </p>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { useFeatureFilter } from '@/shared/components/SideMenu/useFeatureFilter.js'
import {
  alert_info_circle,
  open_in_new,
  pn_document
} from '@/shared/constants/icons.js'
import { reports } from '@/shared/constants/illustrations.js'
import authManagerMixin from '@/shared/mixins/authManagerMixin.js'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  mixins: [authManagerMixin],
  setup() {
    const { filteredFeatures } = useFeatureFilter()
    return {
      filteredFeatures
    }
  },
  data() {
    return {
      bookingItems: [
        {
          name: 'Booking API',
          description: 'EDI Dashboard',
          url: `${process.env.VUE_APP_BOOKING_BO_URL}`,
          meta: {
            icon: pn_document,
            illustration: reports,
            market: ['SE', 'DK', 'NO'],
            isExternal: true,
            requiresAuth: true
          }
        }
      ],
      open_in_new,
      alert_info_circle
    }
  },
  computed: {
    ...mapState('unified', ['accessToken']),
    ...mapGetters('retail', { isRetailLoggedIn: 'isLoggedIn' }),
    ...mapGetters('unified', {
      isUnifiedLoggedIn: 'isLoggedIn'
    }),
    items() {
      return this.filteredFeatures.length > 0
        ? this.filteredFeatures.slice(1)
        : []
    },
    isLoading() {
      return this.items.length === 0
    },
    showBookingItems() {
      return this.bookingItems.length && !this.isRetailLoggedIn
    },
    cardHeaderText() {
      return this.getCardHeaderText(
        !this.isRetailLoggedIn ? 'BUSINESS' : 'SERVICEPOINT'
      )
    }
  },
  methods: {
    overrideClick(event, item) {
      event.preventDefault()
      if (item.url) {
        window.postMessage({ action: 'navigate', href: item.url }, '*')
      } else {
        this.$router.push({ path: item.path })
      }
    },
    translate(text) {
      const isUpperCase = text && text === text.toUpperCase()
      if (this.isRetailLoggedIn || isUpperCase) {
        return this.$t(text)
      }
      return text
    },
    getCardHeaderText(group) {
      const groups = ['SERVICEPOINT', 'BUSINESS', 'BOOKING']
      const header = { title: '', description: '' }
      if (groups.includes(group)) {
        header.title = this.$t(`APP.CARD_HEADER_TITLE_${group}`)
        header.description = this.$t(`APP.CARD_HEADER_DESCRIPTION_${group}`)
      }
      return header
    }
  }
}
</script>
