<template>
  <div class="checkbox" @click="selectBox()">
    <div class="checkbox__left">
      <pn-checkbox
        :checkboxid="`checkbox-${value}`"
        :checked="checked"
        :value="value"
        :data-test="`checkbox-${value}`"
        :disabled="disabled"
        :indeterminate="indeterminate"
        @change="selectBox()"
      />
      {{ label }}
    </div>
    <slot name="component" class="checkbox_right"></slot>
  </div>
</template>
<script setup>
const emit = defineEmits(['select'])
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  checked: {
    type: Boolean,
    default: false
  },
  disabled: Boolean,
  value: {
    type: [String, Number],
    default: ''
  },
  indeterminate: Boolean
})
function selectBox() {
  if (!props.disabled) {
    emit('select', props.value)
  }
}
</script>
<style scoped lang="scss">
.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  pn-checkbox {
    margin-right: 0.5rem;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }
}
</style>
